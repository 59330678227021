import { defaultLocale } from "../types";
import { fixValueToDisplay } from "../lib/formatting";
export const getCryptoFromSymbol = (symbol, cryptocurrencies)=>{
    return cryptocurrencies.find((crypto)=>crypto.symbol == symbol);
};
export const convertCryptoToCrypto = (cryptoA, cryptoB)=>{
    return (cryptoA.last_price || 0) / ((cryptoB == null ? void 0 : cryptoB.last_price) || 1);
};
export const replaceVariableInCryptocurrencyData = (data, locale, symbol, totalSupply, marketCap, currentPrice, fallbackLocale = defaultLocale)=>{
    var _existingLocalizedDescription_faq;
    const existingDescription = data.description_translations;
    const existingLocalizedDescription = (existingDescription == null ? void 0 : existingDescription[locale]) || (existingDescription == null ? void 0 : existingDescription[fallbackLocale]);
    const replaceVariableInText = (text)=>{
        return text == null ? void 0 : text.replaceAll("@total_supply@", `${fixValueToDisplay(totalSupply) || "N/A"} ${symbol}`).replaceAll("@market_cap@", `$${fixValueToDisplay(marketCap) || "N/A"}`).replaceAll("@price@", `$${fixValueToDisplay(currentPrice) || "N/A"}`);
    };
    return {
        ...data,
        description_translations: existingLocalizedDescription ? {
            "what-is": replaceVariableInText(existingLocalizedDescription["what-is"]),
            purpose: replaceVariableInText(existingLocalizedDescription.purpose),
            fundamentals: replaceVariableInText(existingLocalizedDescription.fundamentals),
            competition: replaceVariableInText(existingLocalizedDescription.competition),
            technology: replaceVariableInText(existingLocalizedDescription.technology),
            faq: (_existingLocalizedDescription_faq = existingLocalizedDescription.faq) == null ? void 0 : _existingLocalizedDescription_faq.map((faqItem)=>{
                return {
                    ...faqItem,
                    answer: replaceVariableInText(faqItem.answer)
                };
            })
        } : undefined
    };
};
export const getLocalizedCryptocurrency = (cryptocurrency, locale = defaultLocale, fallbackLocale = defaultLocale)=>{
    return (cryptocurrency == null ? void 0 : cryptocurrency.description_translations) ? replaceVariableInCryptocurrencyData(cryptocurrency, locale, (cryptocurrency == null ? void 0 : cryptocurrency.symbol) || undefined, (cryptocurrency == null ? void 0 : cryptocurrency.total_supply) || undefined, (cryptocurrency == null ? void 0 : cryptocurrency.market_cap) || undefined, (cryptocurrency == null ? void 0 : cryptocurrency.last_price) || undefined, fallbackLocale) : undefined;
};
export const getCryptocurrencySlugFromName = (cryptocurrencyName, separator = "_")=>{
    return cryptocurrencyName.toLowerCase().replaceAll(" ", separator);
};
export const getCryptocurrencyNameFromSlug = (cryptocurrencySlug, separator = "_")=>{
    return cryptocurrencySlug.toLowerCase().replaceAll(separator, " ") // Warning: it doesn't restore case
    ;
};
export const getCryptocurrencySlug = (cryptocurrency)=>{
    return cryptocurrency.name ? getCryptocurrencySlugFromName(cryptocurrency.name) : undefined;
};
