'use client'

import { Box, Flex } from '@chakra-ui/react'
import {
  ProductRiskAttribute,
  ProductWithPlanAndResults,
} from '@astrolab/back/types'
import { StyledTitle } from './Styling/StyledTitle'
import { StyledSubtitle } from './Styling/StyledSubtitle'
import { Exchange } from '@astrolab/back/exchange/exchange.entity'
import { StrategiesGrid } from './Strategies/StrategiesGrid'
import { Cryptocurrency } from '@astrolab/back/public-data/public-data.entity'

const StrategiesPreview: React.FC<{
  bestStrategyByRisk?: Record<
    ProductRiskAttribute,
    ProductWithPlanAndResults | undefined
  >
  strategies?: ProductWithPlanAndResults[] | undefined
  exchanges?: Array<Exchange>
  cryptocurrencies?: Array<Cryptocurrency>
  title: string
  subtitle: string
  contentW: any
  [rest: string]: any
}> = ({
  bestStrategyByRisk,
  strategies,
  exchanges,
  cryptocurrencies,
  title,
  subtitle,
  contentW,
  ...rest
}) => {
  const strategiesToUse =
    strategies ||
    (bestStrategyByRisk
      ? Object.values(bestStrategyByRisk).filter(value => value != undefined)
      : [])

  return (
    <Flex
      direction="column"
      textAlign="center"
      justifyContent="center"
      align="center"
      w="100%"
      position="relative"
      overflowY="hidden"
      px={{ base: '0px', md: '0px' }}
      {...rest}
    >
      <Box w="100%" textAlign="center">
        <StyledTitle px="4" as="h2">
          {title}
        </StyledTitle>
        <StyledSubtitle px="4" fontSize="lg" as="h3" pb={9}>
          {subtitle}
        </StyledSubtitle>
        <StrategiesGrid
          strategies={strategiesToUse}
          exchanges={exchanges}
          cryptocurrencies={cryptocurrencies}
          contentW={contentW}
          mt="20px"
          mb="10px"
        />
      </Box>
    </Flex>
  )
}

export { StrategiesPreview }
